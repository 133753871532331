<template>
  <div class="lyric-editor"></div>
</template>

<script>
import Quill from 'quill'

export default {
  name: 'LyricEditor',
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      content: '',
    }
  },
  beforeDestroy () {
    this.quill = null
  },
  watch: {
    disabled (val) {
      if (this.quill) {
        this.quill.enable(!val)
      }
    },
    value (val, oldVal) {
      if (this.quill) {
        if (val) {
          if (val !== this.content) {
            this.quill.pasteHTML(val)
          }
        } else {
          this.quill.setText('')
        }
      }
    },
  },
  mounted () {
    this.initialize()
  },
  methods: {
    initialize (options) {
      const dom = this.$el
      if (dom) {
        const opts = Object.assign({
          theme: 'snow',
          formats: ['align'],
          boundary: document.body,
          placeholder: this.placeholder,
          readOnly: false,
          modules: {
            toolbar: false,
            // toolbar: [
            //   ['bold', { align: [] }],
            // ]
          },
        }, options)
        this.quill = new Quill(dom, opts)

        if (this.value) {
          this.quill.pasteHTML(this.value)
        }

        this.quill.enable(!this.disabled)

        this.quill.on('text-change', (newDelta, oldDelta, source) => {
          console.log('============ quill text-change')
          const delta = this.quill.getContents()
          const html = this.$el.children[0].innerHTML
          const content = html !== '<p><br></p>' ? html : ''
          this.content = content
          this.$emit('input', content)
          this.$emit('change', { content, delta })
        })
      }
    }
  }
}
</script>

<!--<style lang="scss">-->
<!--.lyric-editor {-->
<!--  border: 1px solid #DCDFE6;-->
<!--  border-radius: 4px;-->
<!--}-->
<!--</style>-->
