<template>
  <el-form class="questionForm" :label-width="isSub ? '76px' : '85px'" label-position="right" label-suffix=":" size="small">
    <el-form-item :label="order ? `第${ order }小问` : '题目'" required>
      <ui-editor v-model="question.stem" @ocr="handleOCR" />
    </el-form-item>
    <slot name="attachment"></slot>
    <el-form-item label="答案">
      <ui-editor v-model="question.answer" @ocr="customOCR" />
    </el-form-item>
    <el-form-item label="解析">
      <ui-editor v-model="question.analysis" @ocr="customOCR" />
    </el-form-item>

    <el-form-item label="章节">
      <el-input :value="searchData.chapterName" readonly class="form-input" />
      <!--<el-select v-model="question.chapter"></el-select>-->
    </el-form-item>
    <el-form-item label="知识点">
      <SelectKnowledge
        v-model="question.knowledge"
        :subsectionSubjectId="searchData.subjectId"
        :options="$attrs.knowledgeOptions"
      />
    </el-form-item>
    <el-form-item label="核心素养">
      <SelectKeyAccomplishment
        v-model="question.keyAccomplishment"
        :options="$attrs.keyAccomplishmentOptions"
      />
    </el-form-item>

    <EditorOCR ref="editorOCR" />
  </el-form>
</template>

<script>
import 'quill/dist/quill.snow.css'
import SelectKeyAccomplishment from './SelectKeyAccomplishment'
import SelectKnowledge from './SelectKnowledge'
import EditorOCR from '@/components/EditorOCR'

export default {
  name: 'QuestionForm',
  components: {
    SelectKeyAccomplishment,
    SelectKnowledge,
    EditorOCR,
  },
  props: {
    isSub: {
      type: Boolean,
      default: false
    },
    order: {
      type: Number,
      default: 0
    },
    question: {
      type: Object,
      required: true
    }
  },
  computed: {
    searchData () {
      return this.$store.state.branchImport.searchData
    }
  },
  methods: {
    handleOCR (callback) {
      this.$emit('ocr', callback)
    },
    customOCR (callback) {
      this.$refs.editorOCR.show(callback)
    },
  }
}
</script>
