<template>
  <el-dialog
    title=""
    :show-close="false"
    :visible.sync="visible"
    width="800px"
    center
  >
    <div class="checkContent">
      <div class="checkLeft">
        <div class="checkTitle"><p>原题</p></div>
        <div class="checkText">
          <div style="display: flex" v-for="(item, index) in content" :key="index">
            <span style="margin-right: 4px" v-if="index > 0">({{ index }})</span>
            <ui-mathpad :value="item" />
          </div>
        </div>
      </div>
      <div class="checkRight">
        <div class="checkTitle"><p>相似题</p></div>
        <div class="checkText">
            <div class="context" v-for="(item, index) in list" :key="index">
              <div class="headers">
                <el-radio-group v-model="bankId">
                  <el-radio :label="item.questionId" @click.native.prevent="check(item)">
                    <span class="similar">第{{ index + 1 }}题</span>
                  </el-radio>
                </el-radio-group>
              </div>
              <ui-mathpad :value="item.stem" />
              <template v-if="item.isCombination">
                <div v-for="(sub, index) in item.subtopicQuestion" :key="sub.questionId">
                  <span style="margin-right:4px">({{ index + 1 }})</span>
                  <ui-mathpad :value="sub.stem" />
                </div>
              </template>
            </div>
        </div>
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'CheckRepeat',
  data () {
    return {
      visible: false,

      bankId: '',
      content: '',
      list: [],
    }
  },
  methods: {
    show (content, list) {
      console.log(content, '***')
      this.content = content
      this.list = list
      this.visible = true
    },

    close () {
      this.visible = false
    },

    check (item) {
      const id = item.questionId
      this.bankId = id === this.bankId ? '' : id
    },

    submit () {
      this.$emit('submit', this.bankId)
      this.visible = false
    }
  }
}
</script>

<style scoped>
.checkContent {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.checkLeft,
.checkRight {
  width: 49%;
  border: 1px solid #18C2C2;
  border-radius: 10px;
  overflow: hidden;
}

.checkTitle {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 600;
  color: #18C2C2;
  background-color: #E8F7F2;
}

.checkText {
  padding: 10px;
  height: 500px;
  overflow: auto;
}

.checkRight .checkText {
  line-height: 25px;
  letter-spacing: 1px;
  background-color: #EEE;
}

.checkLeft .checkText img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.checkRight .checkText img {
  max-width: 100%;
  max-height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.checkRight .checkText .context {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
}

.dialog-footer .el-button {
  width: 120px
}
</style>
